import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    useEffect(() => {
        axios.get("/auth").then((res) => {
            if (res.data) {
                navigate('/')
            }
        });
    }, [navigate])

    return (
        <div className="page-content d-flex align-items-center justify-content-center">
            <div className="row w-100 mx-0 auth-page">
                <div className="col-md-5 mx-auto">
                    <div className="card">
                        <div className="auth-form-wrapper px-4 py-5">
                            <a href="/" className="noble-ui-logo d-block mb-2">Best<span>Feed</span>IQ</a>
                            <h5 className="text-muted fw-normal mb-4">Welcome</h5>
                            <form className="form-sample" onSubmit={(e) => {
                                e.preventDefault();
                                const formData = new FormData(e.target);
                                const data = Object.fromEntries(formData);
                                axios.post(`/login`, data).then(res => {
                                    if (!res.data) {
                                        toast.error(res.data, {
                                            position: "top-left",
                                            autoClose: 3000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        });
                                    } else {
                                        navigate('/')
                                    }
                                });
                            }}>
                                <div className="mb-3">
                                    <label htmlFor="userEmail" className="form-label">Username</label>
                                    <input type="username" className="form-control" name="username" placeholder="username" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="userPassword" className="form-label">Password</label>
                                    <input type="password" className="form-control" name="password" autoComplete="current-password" placeholder="password" />
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0">
                                        Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 small ltr">
                        <p className="text-muted mb-1 mb-md-0 tx-13">© {new Date().getFullYear()} <a href="/">BestFeedIQ</a> </p>
                    </footer>
                </div>
            </div>
        </div>
    );
}

export default Login;