import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import Editor from "../Components/Editor";


const Products = ({ icon }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/product');
        setData(data);

    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    const [content, setContent] = useState({});
    const handleContentChange = (name, value) => {
        setContent(prevContent => ({ ...prevContent, [name]: value }));
    }

    // uploadImage
    const uploadImage = async (e, id) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        console.log(e.target.files[0].name, 'id', id);
        const { data } = await axios.post("/upload", formData);
        await axios.post(`/upload-image`, { product_id: id, image: data });
        getCurrentImages(id);
    };

    const [currentImages, setCurrentImages] = useState([]);
    const getCurrentImages = async (id) => {
        const { data } = await axios.get(`/product-images/${id}`);
        setCurrentImages(data);
    };

    const deleteImage = async (id, pid) => {
        await axios.delete(`/delete-image/${id}`);
        getCurrentImages(pid);
    };

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Products</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Product List</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">New Product</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-md">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="newproduct">New Product</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    formData.append('description_en', content.description_en);
                                                    formData.append('description_ar', content.description_ar);
                                                    formData.append('description_krd', content.content_krd);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/product`, data).then(() => {
                                                        getData();
                                                        toast.success('Product Added Successfully');
                                                        window.$(`#new`).modal('hide');
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="card-title">
                                                            <p className="alert alert-fill-light">English</p>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Product Name</label>
                                                            <input type="text" className="form-control" name="title_en" placeholder="Product Title" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Descriptio</label>
                                                            <Editor name="description_en" targetTextareaId="description_en" onContentChange={(value) => handleContentChange("description_en", value)} />
                                                        </div>

                                                        <div className="card-title">
                                                            <p className="alert alert-fill-light">Arabic</p>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Product Name</label>
                                                            <input type="text" className="form-control" name="title_ar" placeholder="Product Title" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Descriptio</label>
                                                            <Editor name="description_ar" targetTextareaId="description_ar" onContentChange={(value) => handleContentChange("description_ar", value)} />
                                                        </div>

                                                        <div className="card-title">
                                                            <p className="alert alert-fill-light">Kurdish</p>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Product Name</label>
                                                            <input type="text" className="form-control" name="title_krd" placeholder="Product Title" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Descriptio</label>
                                                            <Editor name="description_krd" targetTextareaId="description_krd" onContentChange={(value) => handleContentChange("description_krd", value)} />
                                                        </div>

                                                        <div className="mb-3">
                                                            <label className="form-label">Image</label>
                                                            <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                            <input type="hidden" name="thumbnail" defaultValue={selectedFile} required />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th></th>
                                        <th>Product</th>
                                        <th>Created At</th>
                                        <th>option</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td><img src={item.image} /></td>
                                                <td>{item.title_en}</td>
                                                <td>{new Date(item.created).toLocaleDateString()}</td>
                                                <td>
                                                    <span type="button" className="badge bg-dark text-white  mx-1" data-bs-toggle="modal" data-bs-target={`#upload${item.id}`} onClick={() => getCurrentImages(item.id)} >
                                                        <icon.Upload size="18" />
                                                    </span>
                                                    <span type="button" className="badge bg-warning text-dark  mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                        <icon.Edit size="18" />
                                                    </span>
                                                    <span type="button" className="badge bg-danger mx-1"
                                                        onClick={() => {
                                                            Swal.fire({
                                                                title: 'Are you sure?',
                                                                text: "You won't be able to revert this!",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: "Yes, delete it!",
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    axios.delete(`/product/${item.id}`).then((res) => {
                                                                        getData();
                                                                        toast.success('Product Deleted Successfully');
                                                                    })
                                                                }
                                                            })
                                                        }}>
                                                        <icon.Trash size="18" />
                                                    </span>
                                                </td>
                                                <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="editproduct"> {item.title_en}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                formData.append('description_en', content.description_en || item.description_en);
                                                                formData.append('description_ar', content.description_ar || item.description_ar);
                                                                formData.append('description_krd', content.description_krd || item.description_krd);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/product/${item.id}`, data).then(() => {
                                                                    getData();
                                                                    toast.success('Product Updated Successfully');
                                                                    window.$(`#edit${item.id}`).modal('hide');
                                                                })
                                                            }} >
                                                                <div className="modal-body">
                                                                    <div className="card-title">
                                                                        <p className="alert alert-fill-light">English</p>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Product Name</label>
                                                                        <input type="text" className="form-control" name="title_en" placeholder="Product Title" defaultValue={item.title_en} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Description</label>
                                                                        <Editor name="description_en" targetTextareaId="description_en" defaultValue={item.description_en} onContentChange={(value) => handleContentChange("description_en", value)} />
                                                                    </div>


                                                                    <div className="card-title">
                                                                        <p className="alert alert-fill-light">Arabic</p>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Product Name</label>
                                                                        <input type="text" className="form-control" name="title_ar" placeholder="Product Title" defaultValue={item.title_ar} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Description</label>
                                                                        <Editor name="description_ar" targetTextareaId="description_ar" defaultValue={item.description_ar} onContentChange={(value) => handleContentChange("description_ar", value)} />
                                                                    </div>


                                                                    <div className="card-title">
                                                                        <p className="alert alert-fill-light">Kurdish</p>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Product Name</label>
                                                                        <input type="text" className="form-control" name="title_krd" placeholder="Product Title" defaultValue={item.title_krd} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Description</label>
                                                                        <Editor name="description_krd" targetTextareaId="description_krd" defaultValue={item.description_krd} onContentChange={(value) => handleContentChange("description_krd", value)} />
                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <label className="form-label">Image</label>
                                                                        <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" />
                                                                        <input type="hidden" name="image" defaultValue={selectedFile ? selectedFile : item.image} required />
                                                                        <img className="img-fluid rounded mt-2" src={`../../${item.image}`} alt={item.title} />
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                    <button type="submit" className="btn btn-primary">Update</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="modal fade" id={`upload${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"> Upload Image</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Upload Image</label>
                                                                    <input type="file" className="form-control" onChange={e => (uploadImage(e, item.id))} accept="application/image/*" />
                                                                </div>
                                                                {currentImages && currentImages.map((i, x) => {
                                                                    return (
                                                                        <div key={x}>
                                                                            <img className="img-fluid rounded" src={`../../${i.image}`} alt={item.title} />
                                                                            <button className="btn btn-danger ml-2" onClick={() => deleteImage(i.image_id, item.id)}>Delete</button>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Update</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Products;