import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";
import Editor from "../Components/Editor";

const About = ({ icon }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/about');
        setData(data);
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    const [selectedFile2, setSelectedFile2] = useState(null);
    const uploadChange2 = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile2(data);
    };


    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable();
            }
        }
    }, [data]);


    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const [content, setContent] = useState({});
    const handleContentChange = (name, value) => {
        setContent(prevContent => ({ ...prevContent, [name]: value }));
    }


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">About Us</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">About Us</h6>
                                </div>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>Small Title</th>
                                        <th>Large Title</th>
                                        <th>Description</th>
                                        <th>Signature</th>
                                        <th>Singature's Owner</th>
                                        <th>Option</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{item.small_title_en}</td>
                                                <td>{item.large_title_en}</td>
                                                <td>{item.description_en.length > 100 ? <div dangerouslySetInnerHTML={{ __html: item.description_en.slice(0, 100) + '...' }} /> : <div dangerouslySetInnerHTML={{ __html: item.description_en }} />}</td>
                                                <td>{item.signature}</td>
                                                <td>{item.signatured_by_en}</td>
                                                <td>
                                                    <button className="btn btn-sm btn-outline-success mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                        <icon.Edit size="18" />
                                                    </button>
                                                </td>
                                                <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">Edit</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                formData.append('description_en', content.description_en || item.description_en);
                                                                formData.append('description_ar', content.description_ar || item.description_ar);
                                                                formData.append('description_krd', content.description_krd || item.description_krd);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/about/${item.id}`, data).then(() => {
                                                                    window.$(`#edit${item.id}`).modal('hide');
                                                                    toast.success('updated successfully');
                                                                    getData();
                                                                })
                                                            }} >
                                                                <div className="modal-body">
                                                                    <div className="card-title">
                                                                        <p className="alert alert-fill-light">English</p>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Small Title</label>
                                                                        <input type="text" className="form-control" name="small_title_en" placeholder="Small Title" defaultValue={item.small_title_en} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Large Title</label>
                                                                        <input type="text" className="form-control" name="large_title_en" placeholder="Large Title" defaultValue={item.large_title_en} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Description</label>
                                                                        <Editor name="description_en" targetTextareaId="description_en" defaultValue={item.description_en} onContentChange={(value) => handleContentChange("description_en", value)} />
                                                                    </div>

                                                                    <div className="card-title">
                                                                        <p className="alert alert-fill-light">Arabic</p>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Small Title</label>
                                                                        <input type="text" className="form-control" name="small_title_ar" placeholder="Small Title" defaultValue={item.small_title_ar} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Large Title</label>
                                                                        <input type="text" className="form-control" name="large_title_ar" placeholder="Large Title" defaultValue={item.large_title_ar} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Description</label>
                                                                        <Editor name="description_ar" targetTextareaId="description_ar" defaultValue={item.description_ar} onContentChange={(value) => handleContentChange("description_ar", value)} />
                                                                    </div>

                                                                    <div className="card-title">
                                                                        <p className="alert alert-fill-light">Kurdish</p>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Small Title</label>
                                                                        <input type="text" className="form-control" name="small_title_krd" placeholder="Small Title" defaultValue={item.small_title_krd} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Large Title</label>
                                                                        <input type="text" className="form-control" name="large_title_krd" placeholder="Large Title" defaultValue={item.large_title_krd} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Description</label>
                                                                        <Editor name="description_krd" targetTextareaId="description_krd" defaultValue={item.description_krd} onContentChange={(value) => handleContentChange("description_krd", value)} />
                                                                    </div>


                                                                    <div className="mb-3">
                                                                        <label className="form-label">Signature</label>
                                                                        <input type="text" className="form-control" name="signature" placeholder="signatured" defaultValue={item.signature} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Signatured by (English)</label>
                                                                        <input type="text" className="form-control" name="signatured_by_en" placeholder="signatured by" defaultValue={item.signatured_by_en} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Signatured by (Arabic)</label>
                                                                        <input type="text" className="form-control" name="signatured_by_ar" placeholder="signatured by" defaultValue={item.signatured_by_ar} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Signatured by (Kurdish)</label>
                                                                        <input type="text" className="form-control" name="signatured_by_krd" placeholder="signatured by" defaultValue={item.signatured_by_krd} required />
                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <label className="form-label">Background</label>
                                                                        <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" />
                                                                        <input type="hidden" name="background" defaultValue={selectedFile ? selectedFile : item.background} required />
                                                                        <img className="img-fluid rounded mt-2" src={`../../${item.background}`} alt={item.small_title} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Image</label>
                                                                        <input type="file" className="form-control" onChange={uploadChange2} accept="application/pdf, image/*" />
                                                                        <input type="hidden" name="image" defaultValue={selectedFile2 ? selectedFile2 : item.image} required />
                                                                        <img className="img-fluid rounded mt-2" src={`../../${item.image}`} alt={item.small_title} />
                                                                    </div>

                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">close</button>
                                                                    <button type="submit" className="btn btn-primary">update</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default About;