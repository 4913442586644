import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";

const General = ({ icon }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/setting');
        setData(data);
    };

    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable();
            }
        }
    }, [data]);


    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Site Information</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Site Information</h6>
                                </div>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>Phone No.</th>
                                        <th>E-Mail</th>
                                        <th>Address</th>
                                        <th>Description</th>
                                        <th>Option</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{item.phone_no}</td>
                                                <td>{item.email}</td>
                                                <td>{item.address_en}</td>
                                                <td>{item.description_en}</td>
                                                <td>
                                                    <button className="btn btn-sm btn-outline-success mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                        <icon.Edit size="18" />
                                                    </button>
                                                </td>
                                                <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">Edit</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/setting/${item.id}`, data).then(() => {
                                                                    window.$(`#edit${item.id}`).modal('hide');
                                                                    toast.success('updated successfully');
                                                                    getData();
                                                                })
                                                            }} >
                                                                <div className="modal-body">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Phone No.</label>
                                                                        <input type="text" className="form-control" name="phone_no" placeholder="Phone No." defaultValue={item.phone_no} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">E-Mail</label>
                                                                        <input type="text" className="form-control" name="email" placeholder="E-Mail" defaultValue={item.email} required />
                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <label className="form-label">Google Map</label>
                                                                        <textarea className="form-control" name="map" placeholder="Google Map url" defaultValue={item.map} required />
                                                                    </div>

                                                                    <div className="card-title">
                                                                        <p className="alert alert-fill-light">English</p>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Address</label>
                                                                        <input type="text" className="form-control" name="address_en" placeholder="Address" defaultValue={item.address_en} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Description</label>
                                                                        <textarea name="description_en" className="form-control" defaultValue={item.description_en} />
                                                                    </div>

                                                                    <div className="card-title">
                                                                        <p className="alert alert-fill-light">Arabic</p>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Address</label>
                                                                        <input type="text" className="form-control" name="address_ar" placeholder="Address" defaultValue={item.address_ar} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Description</label>
                                                                        <textarea name="description_ar" className="form-control" defaultValue={item.description_ar} />
                                                                    </div>

                                                                    <div className="card-title">
                                                                        <p className="alert alert-fill-light">Kurdish</p>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Address</label>
                                                                        <input type="text" className="form-control" name="address_krd" placeholder="Address" defaultValue={item.address_krd} required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Description</label>
                                                                        <textarea name="description_krd" className="form-control" defaultValue={item.description_krd} />
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">close</button>
                                                                    <button type="submit" className="btn btn-primary">update</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default General;