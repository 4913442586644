import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";
import Swal from "sweetalert2";

const Slides = ({ icon }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/slide');
        setData(data);
    };

    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable();
            }
        }
    }, [data]);

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);
    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Slides</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Slides</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">New Slide</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">New Slide</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/slide`, data).then(() => {
                                                        window.$(`#new`).modal('hide');
                                                        toast.success('Added successfully');
                                                        getData();
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="card-title">
                                                            <p className="alert alert-fill-light">English</p>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Title</label>
                                                            <input type="text" className="form-control" name="title_en" placeholder="Slide Title" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Shorts</label>
                                                            <textarea type="text" className="form-control" name="shorts_en" placeholder="Slide Shorts" required />
                                                        </div>

                                                        <div className="card-title">
                                                            <p className="alert alert-fill-light">Arabic</p>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Title</label>
                                                            <input type="text" className="form-control" name="title_ar" placeholder="Slide Title" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Shorts</label>
                                                            <textarea type="text" className="form-control" name="shorts_ar" placeholder="Slide Shorts" required />
                                                        </div>

                                                        <div className="card-title">
                                                            <p className="alert alert-fill-light">Kurdish</p>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Title</label>
                                                            <input type="text" className="form-control" name="title_krd" placeholder="Slide Title" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Shorts</label>
                                                            <textarea type="text" className="form-control" name="shorts_krd" placeholder="Slide Shorts" required />
                                                        </div>

                                                        <div className="mb-3">
                                                            <label className="form-label">Image</label>
                                                            <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                            <input type="hidden" name="image" defaultValue={selectedFile} required />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">close</button>
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Title</th>
                                        <th>Shorts</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={index}>
                                            <td><img src={item.image} alt={item.title} className="img-fluid" /></td>
                                            <td>{item.title_en}</td>
                                            <td>{item.shorts_en}</td>
                                            <td>
                                                <span type="button" className="badge bg-warning text-dark  mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                    <icon.Edit size="18" />
                                                </span>
                                                <span type="button" className="badge bg-danger mx-1"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: 'Are you sure?',
                                                            text: "You won't be able to revert this!",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: "Yes, delete it!",
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                axios.delete(`/slide/${item.id}`).then(() => {
                                                                    getData();
                                                                })
                                                            }
                                                        })
                                                    }}>
                                                    <icon.Trash size="18" />
                                                </span>
                                            </td>
                                            <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-md">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="editproduct"> {item.product_name}</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                        </div>
                                                        <form onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = new FormData(e.target);
                                                            const data = Object.fromEntries(formData);
                                                            axios.put(`/slide/${item.id}`, data).then(() => {
                                                                getData();
                                                                toast.success('Slide Updated Successfully');
                                                                window.$(`#edit${item.id}`).modal('hide');
                                                            })
                                                        }} >
                                                            <div className="modal-body">
                                                                <div className="card-title">
                                                                    <p className="alert alert-fill-light">English</p>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Title</label>
                                                                    <input type="text" className="form-control" name="title_en" placeholder="Slide Title" defaultValue={item.title_en} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Shorts</label>
                                                                    <textarea type="text" className="form-control" name="shorts_en" placeholder="Slide Shorts" defaultValue={item.shorts_en} required />
                                                                </div>

                                                                <div className="card-title">
                                                                    <p className="alert alert-fill-light">Arabic</p>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Title</label>
                                                                    <input type="text" className="form-control" name="title_ar" placeholder="Slide Title" defaultValue={item.title_ar} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Shorts</label>
                                                                    <textarea type="text" className="form-control" name="shorts_ar" placeholder="Slide Shorts" defaultValue={item.shorts_ar} required />
                                                                </div>

                                                                <div className="card-title">
                                                                    <p className="alert alert-fill-light">Kurdish</p>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Title</label>
                                                                    <input type="text" className="form-control" name="title_krd" placeholder="Slide Title" defaultValue={item.title_krd} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Shorts</label>
                                                                    <textarea type="text" className="form-control" name="shorts_krd" placeholder="Slide Shorts" defaultValue={item.shorts_krd} required />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label className="form-label">Image</label>
                                                                    <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" />
                                                                    <input type="hidden" name="image" defaultValue={selectedFile ? selectedFile : item.image} required />
                                                                    <img className="img-fluid rounded mt-2" src={`../../${item.image}`} alt={item.title} />
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                <button type="submit" className="btn btn-primary">Update</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Slides;